import React from 'react'

import {Divider} from '@daedalus/atlas/Divider'
import {Box} from '@daedalus/atlas/helpers/Box'

import {ReviewLoading} from './ReviewLoading'

interface Props {
  items?: number
}
export const ReviewsLoadingList: React.FC<Props> = ({items = 5}) => {
  const reviewItems = new Array(items).fill(0)
  return (
    <>
      {reviewItems.map((_, index) => (
        <div key={`review-loading-${index}`}>
          {index > 0 && <Divider />}
          <Box paddingTop="s500" paddingBottom="s500">
            <ReviewLoading />
          </Box>
        </div>
      ))}
    </>
  )
}
