import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity,
  Page,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {getLinkByType} from '@daedalus/core/src/offer/business/getLinkByType'
import {ActionLinkTypes} from '@daedalus/core/src/offer/types/actionLink'

import {
  getAnalyticsContext,
  getComponent,
  getFreezeSelectedContext,
  getIsFreezeOverlayOpen,
  getOfferEligibility
} from '../../modules/selector'
import {State} from '../../modules/slice'
import {closeFreezeOverlay} from '../../modules/slice'
import {FINAL_STATUSES} from '../BookingCreation'
import {FreezeDetailsDesktop} from './FreezeDetailsDesktop'
import {FreezeDetailsMobile} from './FreezeDetailsMobile'
import {useTrackFreezeOverlayViewed} from './useTrackFreezeOverlayViewed'

interface Props {
  // Skips bottom sheet animations (sheet instantly snaps to desired location)
  // This option is used to avoid flaky snapshots in Chromatic
  reduceMotion?: boolean
}

export const FreezeDetails: React.FC<Props> = ({reduceMotion = false}) => {
  const dispatch = useDispatch()
  const {isMobile} = useDeviceLayout()

  const component = useSelector(getComponent)
  const isOpen = useSelector(getIsFreezeOverlayOpen)
  const {isLoading: isOfferCheckLoading, isOfferEligible} =
    useSelector(getOfferEligibility)
  const {offer} = useSelector(getFreezeSelectedContext)

  const {leadContext, offerContext, priceContext} =
    useSelector(getAnalyticsContext)

  const [isPageLoading, setIsOverlayLoading] = useState(true)
  const [isCreateBookingLoading, setCreateBookingLoading] = useState(false)

  const bookingStatus = useSelector(
    (state: {dealFreeze: State}) => state.dealFreeze.bookingStatus
  )
  useTrackFreezeOverlayViewed({isLoading: isPageLoading, isOfferEligible})

  useEffect(() => {
    if (bookingStatus && FINAL_STATUSES.includes(bookingStatus?.status)) {
      setCreateBookingLoading(false)
    }
  }, [bookingStatus])
  const offerLink = getLinkByType(
    offer?.links || undefined,
    ActionLinkTypes.CHECKOUT
  )

  const hideOverlay = () => dispatch(closeFreezeOverlay())

  const handleCreateFreezeBooking = useCallback(() => {
    // render BookingCreation component that initiates the booking creation request
    setCreateBookingLoading(true)

    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.DealFreezeButton,
        action: Action.Clicked,
        analyticsContext: {
          ...(offerContext && {[AnalyticsContext.OfferContext]: offerContext}),
          ...(leadContext && {[AnalyticsContext.LeadContext]: leadContext}),
          ...(priceContext && {[AnalyticsContext.PriceContext]: priceContext})
        },
        page: Page.Accommodation,
        ...(component && {component}),
        team: Team.Select
      })
    )
  }, [dispatch, offerContext, leadContext, priceContext, component])

  useEffect(() => {
    if (isOfferCheckLoading) {
      // If loading, set state immediately
      setIsOverlayLoading(true)
    } else {
      // If not loading, debounce loading state to avoid flash of unloaded content
      const timeout = setTimeout(() => setIsOverlayLoading(false), 50)
      return () => clearTimeout(timeout)
    }
  }, [isOfferCheckLoading])

  if (isMobile) {
    return (
      <FreezeDetailsMobile
        isCreateBookingLoading={isCreateBookingLoading}
        isOpen={isOpen}
        isPageLoading={isPageLoading}
        hideOverlay={hideOverlay}
        handleCreateFreezeBooking={handleCreateFreezeBooking}
        offerLink={offerLink}
        reduceMotion={reduceMotion}
      />
    )
  }

  return (
    <FreezeDetailsDesktop
      isCreateBookingLoading={isCreateBookingLoading}
      isOpen={isOpen}
      isPageLoading={isPageLoading}
      hideOverlay={hideOverlay}
      handleCreateFreezeBooking={handleCreateFreezeBooking}
      offerLink={offerLink}
    />
  )
}
