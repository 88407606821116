import {useEffect} from 'react'

/**
 *
 * useDebouncedEffect is a hook that debounces the amount of times an effect is called
 * @returns Return void
 * @param effect - The effect to be debounced
 * @param deps - The dependencies of the effect
 * @param delay - The delay of the debounce
 * @example
 * ```typescript
 * useDebouncedEffect(() => {
 *   console.log('Hello')
 * }, [count], 300)
 * ```
 */
export const useDebouncedEffect = (
  effect: () => void,
  deps = [],
  delay = 300
) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay)

    return () => clearTimeout(handler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps || []), delay])
}
