import {Amplify} from 'aws-amplify'
import {
  deleteUser,
  fetchAuthSession,
  fetchUserAttributes,
  getCurrentUser,
  signInWithRedirect,
  SignInWithRedirectInput,
  signOut,
  updateUserAttributes,
  UpdateUserAttributesOutput
} from 'aws-amplify/auth'
import {cognitoUserPoolsTokenProvider} from 'aws-amplify/auth/cognito'
import {CookieStorage} from 'aws-amplify/utils'
import {anyPass, isNil, omit, reject} from 'ramda'

import AnalyticsUtils from '../../analytics/utils'
import {UserTier} from '../../offer/business/privateDeals'
import {getAmplifyConfig, getConfigByHostName} from '../amplifyConfig/getConfig'
import {User, UserAttributes, UserPoolId, UserPoolName} from '../types/Cognito'

export {Hub} from 'aws-amplify/utils'

/**
 * Configure Amplify
 */
export const configureAmplify = () => {
  const amplifyConfig = getAmplifyConfig(location.origin)

  Amplify.configure(amplifyConfig)

  cognitoUserPoolsTokenProvider.setKeyValueStorage(
    new CookieStorage(amplifyConfig.cookieStorage as CookieStorage)
  )

  return amplifyConfig
}

/**
 * Amplify SignOut
 */
export const signOutService = async () => {
  await signOut()
  AnalyticsUtils.resetAnalytics()
}

/**
 * Amplify Federated Social Login
 * @param provider - social login provider
 */
export const socialLoginService = async (
  provider: SignInWithRedirectInput['provider']
) => {
  try {
    await signInWithRedirect({
      provider
    })
  } catch (error) {
    console.log(error)
  }
}

interface UserWithPoolId {
  user: User | null
  userPoolId: UserPoolId | ''
}

/**
 * Amplify Get current user
 */
export const getCurrentUserService = async (): Promise<UserWithPoolId> => {
  const {aws_user_pools_id} = getConfigByHostName(location.origin)
  const nullableUser: UserWithPoolId = {user: null, userPoolId: ''}

  try {
    const {idToken} = await getUserSessionService()

    if (idToken) {
      const currentUser = await getCurrentUser()
      const attributes = await getUserAttributesService()

      const {createdAt, updatedAt} = idToken.payload as {
        createdAt: string
        updatedAt: string
      }

      const user: User = {
        ...currentUser,
        id: attributes.sub,
        attributes,
        createdAt,
        updatedAt
      }

      return {user, userPoolId: aws_user_pools_id as UserPoolId}
    }
  } catch (error) {
    return nullableUser
  }

  return nullableUser
}

/**
 * Amplify fetchAuthSession() wrapper method
 * @param forceRefresh - force refresh the session
 */
export const getUserSessionService = async (forceRefresh = false) => {
  const {tokens} = (await fetchAuthSession({forceRefresh})) || {}

  return {
    idToken: tokens?.idToken,
    accessToken: tokens?.accessToken
  }
}

/**
 * Amplify fetchAuthSession() wrapper method
 */
export const getUserAttributesService = async (): Promise<UserAttributes> => {
  const attributes = await fetchUserAttributes()

  const {email_verified, phone_number_verified} = attributes || {}
  const isEmailVerified = email_verified === 'true'
  const isPhoneNumberVerified = phone_number_verified === 'true'

  return {
    ...attributes,
    ...(email_verified && {email_verified: isEmailVerified}),
    ...(phone_number_verified && {phone_number_verified: isPhoneNumberVerified})
  } as UserAttributes
}

/**
 * update user attribute of the current authenticated user
 * @param user - current authenticated user
 * @param attributes - attributes to update
 */
export const updateUserAttributesService = async (
  attributes: UserAttributes
): Promise<UpdateUserAttributesOutput> => {
  const attributesToUpdate = reject(anyPass([isNil]))(
    omit(['email', 'phone_number'], attributes)
  )

  const response = await updateUserAttributes({
    /* @ts-expect-error: we're strongly typing UserAttributes with the values we're returning from Cognito */
    userAttributes: attributesToUpdate
  })

  return response
}

const poolMap = {
  [UserPoolId.Dev]: UserPoolName.Dev,
  [UserPoolId.EtripProd]: UserPoolName.EtripProd,
  [UserPoolId.EtripStg]: UserPoolName.EtripStg,
  [UserPoolId.FindhotelProd]: UserPoolName.FindhotelProd,
  [UserPoolId.FindhotelStg]: UserPoolName.FindhotelStg
}

/**
 * Return the user pool name based on the user pool id
 * @param userPoolId - the user pool id
 */
export const getUserPoolName = (
  userPoolId: UserPoolId | null
): UserPoolName | null => userPoolId && poolMap[userPoolId]

/**
 * Upgrade current authenticated user to specific tier
 * this function does not overwrite previous attributes nor tiers
 * @param tier - the new tier ex: UserTier.Plus
 */
export const upgradeUserTier = async (
  tier: UserTier
): Promise<UserAttributes['custom:app_data']> => {
  const attributes = await getUserAttributesService()

  const appData = JSON.parse(attributes['custom:app_data'] || '{}')

  const previousTiers = appData?.tier || []

  const newAppData = JSON.stringify({
    ...appData,
    tier: [...new Set([...previousTiers, tier])]
  })

  await updateUserAttributesService({'custom:app_data': newAppData})
  return newAppData
}

export const deleteUserService = () => deleteUser()
