import React from 'react'
import {useIntl} from 'react-intl'

import {ButtonLink} from '@daedalus/atlas/ButtonLink'
import {Box} from '@daedalus/atlas/helpers/Box'
import {Text} from '@daedalus/atlas/Text'

import {reviewMessages} from '../ReviewMessages'
import {TranslationState} from './ReviewCommentWrapper'

export interface ReviewTranslationProps {
  translationState: TranslationState
  onTranslate: () => void
  onShowOriginal: () => void
}
export const ReviewTranslation: React.FC<ReviewTranslationProps> = ({
  translationState,
  onTranslate,
  onShowOriginal
}) => {
  const {formatMessage} = useIntl()

  const handleTranslate = (event: React.MouseEvent) => {
    event.stopPropagation()
    onTranslate()
  }
  const handleShowOriginal = (event: React.MouseEvent) => {
    event.stopPropagation()
    onShowOriginal()
  }

  switch (translationState) {
    case 'translated': {
      return (
        <Box height="s600" display="flex" alignItems="center" gap="s300">
          <Text variant="bodyXS" colorPath="content.neutral.c600">
            {formatMessage(reviewMessages.reviewSectionTranslated)}
          </Text>
          <ButtonLink
            variant="info"
            onClick={handleShowOriginal}
            size="sm"
            isCompact
          >
            {formatMessage(reviewMessages.reviewSectionOriginal)}
          </ButtonLink>
        </Box>
      )
    }
    case 'translating': {
      return (
        <Box height="s600" display="flex" alignItems="center">
          <Text variant="bodyXS" colorPath="content.neutral.c600">
            {formatMessage(reviewMessages.reviewSectionTranslating)}
          </Text>
        </Box>
      )
    }

    default: {
      return (
        <Box height="s600" display="flex" alignItems="center">
          <ButtonLink
            variant="info"
            onClick={handleTranslate}
            size="sm"
            isCompact
          >
            {formatMessage(reviewMessages.reviewSectionTranslate)}
          </ButtonLink>
        </Box>
      )
    }
  }
}
