import {createSelector} from '@reduxjs/toolkit'

import {RootState} from '../../testutils/configureStore'
import {priceWatchApi} from '../services'

export interface GetPriceWatchKeyParams {
  checkIn: string
  checkOut: string
  rooms: string
  hotelId: string
  currency: string
}

const getPriceWatchKey = (params: GetPriceWatchKeyParams): string =>
  `${params.checkIn}#${params.checkOut}#${params.rooms}#${params.hotelId}#${params.currency}`

const selectItineraryResult = (userId: string) =>
  priceWatchApi.endpoints.getUserPriceWatchTrackings.select({userId})

const selectItineraryList = (userId: string) =>
  createSelector(selectItineraryResult(userId), result => result.data ?? [])

const selectPriceWatchMap = (userId: string) =>
  createSelector(selectItineraryList(userId), list =>
    Object.fromEntries(list.map(item => [getPriceWatchKey(item), item]))
  )

export const selectPriceWatchEntry = (
  params: GetPriceWatchKeyParams & {userId: string}
) =>
  createSelector(
    selectPriceWatchMap(params.userId),
    priceWatchMap => priceWatchMap[getPriceWatchKey(params)]
  )

export const selectIsHoldOutGroup = (state: RootState) =>
  state.priceWatch.isHoldOutGroup
