import {defineMessages} from 'react-intl'

export const reviewMessages = defineMessages({
  reviewSectionTranslate: {
    id: 'reviewSectionTranslate',
    defaultMessage: 'Translate'
  },
  reviewSectionTranslating: {
    id: 'reviewSectionTranslating',
    defaultMessage: 'Loading translation...'
  },
  reviewSectionTranslated: {
    id: 'reviewSectionTranslated',
    defaultMessage: 'Translated by Google'
  },
  reviewSectionOriginal: {
    id: 'reviewSectionOriginal',
    defaultMessage: 'Show original'
  },
  reviewSectionNoResultsTitle: {
    id: 'reviewSectionNoResultsTitle',
    defaultMessage: 'We found no matches'
  },
  reviewSectionNoResultsDescription: {
    id: 'reviewSectionNoResultsDescription',
    defaultMessage: 'Try adjusting your search to see more reviews.'
  },
  reviewSectionSearchPlaceholder: {
    id: 'reviewSectionSearchPlaceholder',
    defaultMessage: 'Search in reviews'
  }
})
