import React, {lazy, Suspense} from 'react'

import {ANIMATION_TYPES, Overlay} from '@daedalus/atlas/Overlay'
import {Spinner} from '@daedalus/atlas/Spinner'
import {cssTheme} from '@daedalus/atlas/themes'

import {PANEL_WIDTH} from '../constants'

interface Props {
  isOpen: boolean
  onClose: () => void
}

const LazyTms = lazy(async () => import('./TmsPanel'))

export const TmsPanelPopup = ({isOpen, onClose}: Props) => {
  return (
    <Overlay
      containerStyles={{
        width: `${PANEL_WIDTH}px`,
        boxShadow: cssTheme.shadows.raised
      }}
      isOpen={isOpen}
      animationType={ANIMATION_TYPES.NONE}
      onClose={onClose}
      shadeBackground={false}
      closeOnEsc
    >
      <Suspense fallback={<Spinner />}>
        <LazyTms onClose={onClose} />
      </Suspense>
    </Overlay>
  )
}
