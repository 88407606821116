import * as React from 'react'
import {useEffect, useState} from 'react'

import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'

import {TmsPanelPopup} from './components/TmsPanelPopup'
import {APP_ENV_PRODUCTION_LIST} from './constants'

interface Props {
  appEnv: string
}

export const TmsPanelProvider = ({appEnv}: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const {brandIsDemo} = useBrand()

  const isTmsPanelAllowed =
    !APP_ENV_PRODUCTION_LIST.includes(appEnv) || brandIsDemo

  const onClose = () => {
    if (isOpen) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === '.' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        setIsOpen(isOpen => !isOpen)
      }
    }

    if (isTmsPanelAllowed) {
      document.addEventListener('keydown', down)
      return () => document.removeEventListener('keydown', down)
    }
  }, [isTmsPanelAllowed])

  if (!isTmsPanelAllowed) {
    return null
  }

  return <TmsPanelPopup isOpen={isOpen} onClose={onClose} />
}
