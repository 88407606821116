import React, {useCallback} from 'react'
import {defineMessages} from 'react-intl'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {useDispatchTeamEvent} from '@daedalus/core/src/analytics/hooks/useDispatchTeamEvent'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'

import {ReviewSortingDesktop} from './ReviewSortingDesktop'
import {ReviewSortingMobile} from './ReviewSortingMobile'
export const sortList = [
  {
    key: 'mostRelevant',
    value: 'relevancy'
  },
  {
    key: 'newestFirst',
    value: 'recency'
  },
  {
    key: 'highestScores',
    value: 'rating_desc'
  },
  {
    key: 'lowestScores',
    value: 'rating_asc'
  }
]

export const sortLabels = defineMessages({
  newestFirst: {
    id: 'reviews.newestFirst',
    defaultMessage: 'Newest first'
  },
  mostRelevant: {
    id: 'reviews.mostRelevant',
    defaultMessage: 'Most relevant'
  },
  lowestScores: {
    id: 'reviews.lowestScores',
    defaultMessage: 'Lowest scores'
  },
  highestScores: {
    id: 'reviews.highestScores',
    defaultMessage: 'Highest scores'
  }
})

interface Props {
  onChange: (value: string) => void
}

// Added as part of 9633c72c-guest-reviews-sort-filter experiment
export const ReviewSorting: React.FC<Props> = ({onChange}) => {
  const {isDesktop} = useDeviceLayout()
  const dispatchTeamEvent = useDispatchTeamEvent()
  const handleSortClick = useCallback(() => {
    dispatchTeamEvent(
      trackEvent({
        category: Category.User,
        entity: Entity.ReviewSort,
        action: Action.Clicked
      })
    )
  }, [dispatchTeamEvent])

  const handleSortSelect = useCallback(
    (value: string) => {
      dispatchTeamEvent(
        trackEvent({
          category: Category.User,
          entity: Entity.ReviewSort,
          action: Action.Submitted,
          payload: {
            sortField: value
          }
        })
      )
      onChange(value)
    },
    [dispatchTeamEvent, onChange]
  )

  if (isDesktop) {
    return (
      <ReviewSortingDesktop
        onSortChange={handleSortSelect}
        onSortClick={handleSortClick}
      />
    )
  }

  return (
    <ReviewSortingMobile
      onSortChange={handleSortSelect}
      onSortClick={handleSortClick}
    />
  )
}
