import React from 'react'
import {useSelector} from 'react-redux'
import {useCheckPriceWatchMismatch} from 'components/PriceWatch/hooks/useCheckPriceWatchMismatch'
import {getCurrencyCode} from 'modules/meta/selectors'
import {
  getAppLockedDealConfig,
  getNumberOfGuests,
  getNumberOfRooms,
  getShowTotalPrices,
  getSplitBookingPriceSummary
} from 'modules/sapiSearch/selectors'
import {
  getClickedOfferRate,
  getIsLockedDealExcludingGHA
} from 'modules/search/selectors'
import {getIsMultiRoomBundlesSearch} from 'modules/search/selectors'

import {PriceSize} from '@daedalus/atlas/Price'
import {PriceComposition} from '@daedalus/atlas/PriceComposition'
import {hasAnchorPrice} from '@daedalus/core/src/offer/business/price'
import {SearchOffer} from '@daedalus/core/src/offer/types/SearchOffer'
import {getNightlyPriceForAllRooms} from '@daedalus/core/src/price/business/price'
import {getRoundingStrategy} from '@daedalus/core/src/utils/number'

interface SplitBookingProps {
  className?: string
  showPriceIndication?: boolean
  size?: PriceSize
  alwaysShowNightlyPrice?: boolean
  anchorPrice?: number
  anchorPriceTotal?: number
  showAnchorPrice?: boolean
  showAppIcon?: boolean
  hotelId: string
  dataId?: string
}

interface BasePriceProps {
  offer: SearchOffer
  isPrivateDeal: boolean
  anchorPrice: number
  shouldDisplayInline?: boolean
  shouldAlignOffer?: boolean
  showRowLayout?: boolean
  showPriceIndication?: boolean
  size?: PriceSize
  hotelId: string
  isSplitBooking?: boolean
  anchorPriceTotal?: number
  showLockIcon?: boolean
  showAppIcon?: boolean
  className?: string
  showAnchorPrice?: boolean
  alwaysShowNightlyPrice?: boolean
  isRequiredOneTimePasswordFlow?: boolean
  dataId?: string
  /**
   * Added as part of 2a3185b4-mobile-hotel-card-no-offers
   * This option prevents the price from being styled as a private deal, locked deal, or app locked deal
   */
  noSpecialStyle?: boolean
}

const SplitBookingPrice = ({
  anchorPrice,
  anchorPriceTotal,
  showAnchorPrice = false,
  className,
  showPriceIndication,
  size = 'xl',
  alwaysShowNightlyPrice = false,
  hotelId,
  dataId
}: SplitBookingProps) => {
  const currencyCode = useSelector(getCurrencyCode)
  const sbPriceSummary = useSelector(state =>
    getSplitBookingPriceSummary(state, hotelId)
  )
  const combinedAveragePriceNightly = sbPriceSummary.average
  const combinedPriceTotal = sbPriceSummary.sum
  const showTotalPrices =
    useSelector(getShowTotalPrices) && !alwaysShowNightlyPrice

  return (
    <PriceComposition
      dataId={dataId}
      className={className}
      showAnchorPrice={
        showAnchorPrice &&
        hasAnchorPrice(combinedAveragePriceNightly, anchorPrice)
      }
      anchorPrice={showTotalPrices ? anchorPriceTotal : anchorPrice}
      currencyCode={currencyCode}
      price={showTotalPrices ? combinedPriceTotal : combinedAveragePriceNightly}
      size={size}
      showIndication={showPriceIndication}
      roundingStrategy={getRoundingStrategy()}
      showTotal={showTotalPrices}
    />
  )
}

const BasePrice = ({
  offer,
  anchorPrice,
  shouldDisplayInline = false,
  shouldAlignOffer = false,
  showRowLayout = false,
  showPriceIndication = false,
  size = 'xl',
  hotelId,
  anchorPriceTotal,
  showLockIcon = false,
  showAppIcon = false,
  className = '',
  showAnchorPrice = true,
  alwaysShowNightlyPrice = false,
  isRequiredOneTimePasswordFlow,
  isPrivateDeal = false,
  noSpecialStyle = false,
  dataId
}: BasePriceProps) => {
  const isMultiRoomBundlesSearch = useSelector(getIsMultiRoomBundlesSearch)

  const numberOfRooms = useSelector(state => getNumberOfRooms(state, hotelId))
  const numberOfGuests = useSelector(getNumberOfGuests)

  const currencyCode = useSelector(getCurrencyCode)
  const showTotalPrices =
    useSelector(getShowTotalPrices) && !alwaysShowNightlyPrice

  const isLockedDeal = useSelector(getIsLockedDealExcludingGHA(offer))
  const clickedOfferRate = useSelector(getClickedOfferRate)
  const {isAppLockedDeal} = useSelector(state =>
    getAppLockedDealConfig(state, hotelId, offer?.id)
  )

  const {nightlyRate, hasAnchorPrice, totalRate} = offer

  const {isPriceDecrease, isPriceIncrease} = useCheckPriceWatchMismatch({
    hotelId,
    clickedOfferRate
  })

  const hideStrikethroughOnPriceMismatch = Boolean(
    isPriceDecrease || isPriceIncrease
  )

  const isAnchorPriceVisible =
    !hideStrikethroughOnPriceMismatch &&
    Boolean(showAnchorPrice && hasAnchorPrice)

  const calculatedNightlyPrice = getNightlyPriceForAllRooms(
    nightlyRate,
    isMultiRoomBundlesSearch,
    numberOfRooms
  )

  const calculatedAnchorPrice = getNightlyPriceForAllRooms(
    anchorPrice,
    isMultiRoomBundlesSearch,
    numberOfRooms
  )

  const anchorRate = showTotalPrices
    ? anchorPriceTotal * numberOfRooms
    : calculatedAnchorPrice

  return (
    <PriceComposition
      dataId={dataId}
      className={className}
      size={size}
      showLockIcon={showLockIcon}
      showAppIcon={showAppIcon}
      showRowLayout={showRowLayout}
      showAnchorPrice={isAnchorPriceVisible}
      price={showTotalPrices ? totalRate : calculatedNightlyPrice}
      anchorPrice={anchorRate || undefined}
      currencyCode={currencyCode}
      isPrivateDeal={!noSpecialStyle && isPrivateDeal}
      isLockedDeal={
        !noSpecialStyle && (isLockedDeal || isRequiredOneTimePasswordFlow)
      }
      isAppLockedDeal={!noSpecialStyle && isAppLockedDeal}
      shouldDisplayInline={shouldDisplayInline}
      shouldAlignOffer={shouldAlignOffer}
      roundingStrategy={getRoundingStrategy()}
      numberOfGuests={numberOfGuests}
      showIndication={showPriceIndication}
      showTotal={showTotalPrices}
    />
  )
}

type Props = (BasePriceProps | SplitBookingProps) & {isSplitBooking?: boolean}

export const OfferPrice: React.FC<Props> = ({
  isSplitBooking = false,
  ...props
}) => {
  if (isSplitBooking) {
    return <SplitBookingPrice {...props} />
  }

  return <BasePrice {...(props as BasePriceProps)} />
}
