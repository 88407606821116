import React, {useCallback, useContext, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {HotelCardContext} from 'components/HotelCardContext'
import {TabId} from 'components/HotelDetailsTabs'
import {SecondaryOfferList} from 'components/OfferList/SecondaryOfferList'
import {MoreDealsButtonHorizontal} from 'components/OfferList/SecondaryOfferList/MoreDealsButton/MoreDealsButtonHorizontal'
import {MoreDealsButtonVertical} from 'components/OfferList/SecondaryOfferList/MoreDealsButton/MoreDealsButtonVertical'
import {getSecondaryOffers} from 'components/SplitBookings/helpers'
import {useHotelCardActions} from 'hooks/useHotelCardActions'
import {useOfferContainer} from 'hooks/useOfferContainer'
import {
  getParametrizedHotelContext,
  getParametrizedHotelInfoContext
} from 'middleware/analytics/selectors'
import {getDeviceLayout} from 'modules/meta/selectors'
import {showOverlay} from 'modules/overlay/slice'
import {OverlayType} from 'modules/overlay/types'
import {
  getAnchorComplete,
  getIsSearchPending,
  getOfferEntity,
  getSplitBookingDetails
} from 'modules/sapiSearch/selectors'
import {getIsOTAMode} from 'modules/search/selectors'
import {toggle} from 'opticks'
import {SelectedElement} from 'utils/selectedElement'

import {Divider} from '@daedalus/atlas/Divider'
import {Box} from '@daedalus/atlas/helpers/Box'
import {NoStyleButton} from '@daedalus/atlas/helpers/NoStyleButton'
import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {AnalyticsContext} from '@daedalus/core/src/analytics/types/Events'

export const OfferList = () => {
  const {hotelId, isAnchor, isHotelDetailsOverlay} =
    useContext(HotelCardContext)
  const dispatch = useDispatch()
  const isSearchPending = useSelector(getIsSearchPending)
  const anchorComplete = useSelector(getAnchorComplete)
  const offerEntity = useSelector(state => getOfferEntity(state, hotelId))
  const deviceLayout = useSelector(getDeviceLayout)
  const {trackUserHotelCardClicked, onShowHotelDetails} = useHotelCardActions()

  const {
    splitBookingOffers,
    showSplitBookingLoader,
    hasSplitBookingOffer,
    splitBookingPosition,
    splitBookingType,
    offerPositionToRemove,
    splitBookingBundle
  } = useSelector(state => getSplitBookingDetails(state, hotelId))
  const hasSingleFlowSBOffer =
    hasSplitBookingOffer && splitBookingType === 'single_flow'

  const isOTAMode = useSelector(getIsOTAMode)
  const {brandHasDesktopMoreDealsButton} = useBrand()
  const {topOffers, anchorPrice, anchorPriceTotal} = useOfferContainer({
    offerEntity,
    hotelId,
    isOTAMode
  })

  const searchComplete = !isSearchPending || (anchorComplete && isAnchor)

  const secondaryOffers = useMemo(
    () =>
      getSecondaryOffers({
        topOffers,
        hasSplitBookingOffer,
        splitBookingType,
        splitBookingPosition,
        offerPositionToRemove,
        showSplitBookingLoader,
        deviceLayout
      }),
    [
      topOffers,
      hasSplitBookingOffer,
      splitBookingType,
      splitBookingPosition,
      offerPositionToRemove,
      showSplitBookingLoader,
      deviceLayout
    ]
  )

  const hasSecondaryOffersToShow = secondaryOffers?.length > 0
  const singleFlowSBOfferPosition = hasSingleFlowSBOffer
    ? splitBookingPosition
    : 2

  // Added as a part of 70766997-desktop-hotel-card-secondary-offers
  const isSecondaryInlineOffers = toggle(
    '70766997-desktop-hotel-card-secondary-offers',
    false,
    true
  )

  const hasMoreDealsButton = useMemo(() => {
    return (
      hasSecondaryOffersToShow &&
      brandHasDesktopMoreDealsButton &&
      offerEntity.hasMoreOffers
    )
  }, [hasSecondaryOffersToShow, brandHasDesktopMoreDealsButton, offerEntity])

  const handleSeeMoreClick = useCallback(() => {
    if (isHotelDetailsOverlay) return

    trackUserHotelCardClicked({
      hotelId: hotelId,
      component: 'ListViewHotelCard',
      selectedElement: SelectedElement.ShowAllDeals
    })

    toggle(
      '377fd843-desktop-ap--v2',
      () => {
        const analyticsContext = {
          [AnalyticsContext.HotelContext]: getParametrizedHotelContext(hotelId),
          [AnalyticsContext.HotelInfoContext]:
            getParametrizedHotelInfoContext(hotelId)
        }

        onShowHotelDetails(hotelId, analyticsContext, TabId.Deals)
      },
      () => {
        dispatch(
          showOverlay({
            type: OverlayType.HotelAllOffers,
            params: {hotelId: hotelId}
          })
        )
      }
    )
  }, [
    dispatch,
    hotelId,
    trackUserHotelCardClicked,
    isHotelDetailsOverlay,
    onShowHotelDetails
  ])

  if ((!hasSecondaryOffersToShow && searchComplete) || isOTAMode) {
    return null
  }

  if (!hasSecondaryOffersToShow) {
    return (
      <>
        <Divider sideMargin="s500" />
        {isSecondaryInlineOffers ? (
          <SecondaryOfferList isLoading variant="verticalInline" />
        ) : (
          <Box
            paddingTop="s300"
            paddingBottom="s300"
            paddingLeft="s400"
            paddingRight="s400"
          >
            <SecondaryOfferList isLoading variant="horizontal" />
          </Box>
        )}
      </>
    )
  }

  return (
    <>
      <Divider sideMargin="s500" />
      <SecondaryOfferList
        variant={isSecondaryInlineOffers ? 'verticalInline' : 'horizontal'}
        anchorPrice={anchorPrice}
        anchorPriceTotal={anchorPriceTotal}
        hotelId={hotelId}
        secondaryOffers={secondaryOffers}
        sourceComponent="DesktopTopOffers"
        splitBookingOffers={splitBookingOffers}
        showSplitBookingLoader={showSplitBookingLoader}
        splitBookingPosition={singleFlowSBOfferPosition}
        splitBookingType={splitBookingType}
        splitBookingBundle={splitBookingBundle}
        viewMoreSlot={
          isSecondaryInlineOffers ? (
            <MoreDealsButtonVertical onClick={handleSeeMoreClick} />
          ) : (
            hasMoreDealsButton && (
              <NoStyleButton onClick={handleSeeMoreClick}>
                <MoreDealsButtonHorizontal />
              </NoStyleButton>
            )
          )
        }
      />
    </>
  )
}
