import {useCallback, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {TabId} from 'components/HotelDetailsTabs'
import {
  getParametrizedHotelContext,
  getParametrizedHotelInfoContext
} from 'middleware/analytics/selectors'
import {hideOverlay} from 'modules/overlay/slice'
import {Hotel} from 'modules/sapiSearch/slice'

import {useDispatchTeamEvent} from '@daedalus/core/src/analytics/hooks/useDispatchTeamEvent'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  AnalyticsContextList,
  AnalyticsContextPayload,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'

import {useShowAccommodationPage} from './useShowAccommodationPage'

interface TrackUserHotelCardClickedParameters {
  hotelId: Hotel['objectID']
  component:
    | 'ListViewHotelCard'
    | 'MapViewHotelCard'
    | 'MobilePopUpDealModal'
    // Added as part of 2a3185b4-mobile-hotel-card-no-offers
    | 'ExperimentalNoOffersCardLayout'
  selectedElement: string
  analyticsContext?: Partial<Record<AnalyticsContext, AnalyticsContextPayload>>
  isTeamEvent?: boolean
  payload?: Record<string, unknown>
}

// Added as part of 2a3185b4-mobile-hotel-card-no-offers
interface TrackUserHotelCardViewedParameters {
  hotelId: Hotel['objectID']
  displayPricePerRoomPerNight: number
  totalPrice: number
  analyticsContext?: Partial<Record<AnalyticsContext, AnalyticsContextPayload>>
  isTeamEvent?: boolean
  payload?: Record<string, unknown>
}
// END Added as part of 2a3185b4-mobile-hotel-card-no-offers

export const useHotelCardActions = () => {
  const dispatch = useDispatch()
  const dispatchTeamEvent = useDispatchTeamEvent()
  const {showAccommodationPage} = useShowAccommodationPage()
  const handleTrackUserHotelCardClicked = useCallback(
    ({
      hotelId,
      component,
      selectedElement,
      analyticsContext,
      payload,
      isTeamEvent = false
    }: TrackUserHotelCardClickedParameters) => {
      const dispatcher = isTeamEvent ? dispatchTeamEvent : dispatch

      const defaultAnalyticsContext = {
        [AnalyticsContext.HotelContext]: getParametrizedHotelContext(hotelId),
        [AnalyticsContext.HotelInfoContext]:
          getParametrizedHotelInfoContext(hotelId)
      }

      dispatcher(
        trackEvent({
          category: Category.User,
          entity: Entity.HotelCard,
          action: Action.Clicked,
          analyticsContext: analyticsContext || defaultAnalyticsContext,
          component,
          payload: {
            selectedElement,
            ...payload
          }
        })
      )
    },
    [dispatch, dispatchTeamEvent]
  )

  // Added as part of 2a3185b4-mobile-hotel-card-no-offers
  const handleTrackUserHotelCardViewed = useCallback(
    ({
      hotelId,
      displayPricePerRoomPerNight,
      totalPrice,
      analyticsContext,
      payload,
      isTeamEvent = false
    }: TrackUserHotelCardViewedParameters) => {
      const dispatcher = isTeamEvent ? dispatchTeamEvent : dispatch

      const defaultAnalyticsContext = {
        [AnalyticsContext.HotelContext]: getParametrizedHotelContext(hotelId),
        [AnalyticsContext.HotelInfoContext]:
          getParametrizedHotelInfoContext(hotelId)
      }

      dispatcher(
        trackEvent({
          category: Category.User,
          entity: Entity.HotelCard,
          action: Action.Viewed,
          analyticsContext: analyticsContext || defaultAnalyticsContext,
          payload: {
            displayPricePerRoomPerNight,
            totalPrice,
            ...payload
          }
        })
      )
    },
    [dispatch, dispatchTeamEvent]
  )
  // END Added as part of 2a3185b4-mobile-hotel-card-no-offers

  const handleShowHotelDetails = useCallback(
    (
      hotelId: Hotel['objectID'],
      analyticsContext: AnalyticsContextList,
      activeTab: TabId = TabId.Photos
    ) => {
      showAccommodationPage({
        hotelId,
        params: {activeTab},
        analyticsContext
      })
    },
    [dispatch, showAccommodationPage]
  )

  const handleHideHotelDetails = useCallback(() => {
    dispatch(hideOverlay())
  }, [dispatch])

  const cardActions = useMemo(
    () => ({
      onShowHotelDetails: handleShowHotelDetails,
      onHideHotelDetails: handleHideHotelDetails,
      /**
       * Dispatches the `User_HotelCard_Clicked` tracking event
       */
      trackUserHotelCardClicked: handleTrackUserHotelCardClicked,
      /**
       * Dispatches the `User_HotelCard_Viewed` tracking event - Experimental
       */
      trackUserHotelCardViewed: handleTrackUserHotelCardViewed
    }),
    [
      handleShowHotelDetails,
      handleHideHotelDetails,
      handleTrackUserHotelCardClicked,
      handleTrackUserHotelCardViewed
    ]
  )

  return cardActions
}
