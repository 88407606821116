import React from 'react'

import {
  Entity,
  Page,
  PerformanceMetric
} from '@daedalus/core/src/analytics/types/Events'

interface Props {
  entity: Entity
  name: PerformanceMetric
  page: Page
}

/**
 * This component is used to track performance timings. In order for it to work,
 * make sure <PerformanceMonitor> is mounted somewhere in your application.
 */
const TrackPerformanceTiming = ({entity, name, page}: Props) => {
  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img
      src="/static/pixel.jpg"
      style={{
        position: 'absolute',
        width: 1
      }}
      // @ts-expect-error - HTMLImgElement does not have elementtiming
      // eslint-disable-next-line react/no-unknown-property
      elementtiming={`${entity}_${name}`}
      data-page={page}
    />
  )
}

export default React.memo(TrackPerformanceTiming)
