import React, {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ReviewCarousel} from 'components/Reviews/ReviewCarousel'
import {showGuestReviewsOverlay} from 'modules/overlay/actions'
import {getLanguageCode} from 'utils/localization/language'

import {Button} from '@daedalus/atlas/Button'
import {Box} from '@daedalus/atlas/helpers/Box'
import {SkeletonLoader} from '@daedalus/atlas/SkeletonLoader'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {useGetUserReviewsQuery} from '@daedalus/core/src/sapi/services/searchApi'

import {SelectedElementType} from '../DateGuestInput/ReviewsTrackClick'

interface Props {
  hotelId?: string
  hasGuestReviews: boolean
}

export const ReviewCarouselSection: React.FC<Props> = ({
  hotelId,
  hasGuestReviews
}) => {
  const dispatch = useDispatch()
  const languageCode = useSelector(getLanguageCode)

  const {data, isLoading} = useGetUserReviewsQuery(
    {
      hotelId,
      language: languageCode,
      pageSize: 10
    },
    {
      skip: !hasGuestReviews || !hotelId
    }
  )

  const handleReadAllReviews = useCallback(() => {
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.ReviewDetails,
        action: Action.Clicked,
        payload: {
          selectedElement: SelectedElementType.ReviewCard
        }
      })
    )
    dispatch(showGuestReviewsOverlay())
  }, [dispatch])

  const shouldShowAllReviewsSection = Boolean(data?.reviews?.length)
  const firstSixReviews = data?.reviews?.slice(0, 6)

  if (isLoading) return <SkeletonLoader height={250} />

  if (!shouldShowAllReviewsSection) return null

  return (
    <Box display="flex" flexDirection="column" gap="s500">
      <ReviewCarousel
        reviews={firstSixReviews}
        handleReadMoreClick={handleReadAllReviews}
      />
      <Button
        variant="secondary"
        fullWidth
        size="xl"
        onClick={handleReadAllReviews}
      >
        <FormattedMessageWrapper
          id="readAllReviews"
          defaultMessage="Read all reviews"
        />
      </Button>
    </Box>
  )
}
