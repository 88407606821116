import React, {useRef, useState} from 'react'
import {useIntl} from 'react-intl'

import {Box} from '@daedalus/atlas/helpers/Box'
import {Icon} from '@daedalus/atlas/Icon'
import {InputText} from '@daedalus/atlas/InputText'
import {useDebouncedEffect} from '@daedalus/core/src/utils/debounce/hooks/useDebouncedEffect'

import {reviewMessages} from '../ReviewMessages'

interface Props {
  onSearch: (search: string[]) => void
}
// Added as part of 9633c72c-guest-reviews-sort-filter experiment
export const ReviewSearchInput: React.FC<Props> = ({onSearch}) => {
  const {formatMessage} = useIntl()
  const inputRef = useRef<HTMLInputElement>(null)

  const [searchReview, setSearchReview] = useState('')

  const handleSubmit = () => {
    const keywords = searchReview.trim().split(' ').filter(Boolean)

    onSearch(keywords)
  }

  const handleClear = () => {
    setSearchReview('')
    onSearch([])
  }

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    inputRef.current?.blur()
    handleSubmit()
  }

  useDebouncedEffect(() => handleSubmit(), [searchReview], 500)

  return (
    <Box paddingBottom="s600">
      <form onSubmit={handleFormSubmit}>
        <InputText
          ref={inputRef}
          dataId="reviewSectionSearchInput"
          id="searchReview"
          name="searchReview"
          icon={<Icon name="Search" colorPath="content.special.c700" />}
          value={searchReview}
          onChange={setSearchReview}
          onClear={handleClear}
          placeholder={formatMessage(
            reviewMessages.reviewSectionSearchPlaceholder
          )}
          isRounded
          size="md"
        />
      </form>
    </Box>
  )
}
